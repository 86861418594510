import {createRouter, createWebHistory} from 'vue-router'
const router=createRouter({
    history:createWebHistory(),
    routes:[
        {
            path:'/',
            name:"home",
            component:  () => import("@/components/pages/Index.vue"),
            
        },
        {
            path:'/about',
            name:"about",
            component:  () => import("@/components/pages/AboutUs.vue"),
        },
        {
            path:'/service',
            name:"service",
            component:  () => import("@/components/pages/Services.vue"),
        },
        {
            path:'/contact',
            name:"contact",
            component:  () => import("@/components/pages/Contact.vue"),
        },
        {
            path:'/team',
            name:"team",
            component:  () => import("@/components/pages/Team.vue"),
        },
        {
            path:'/pricing',
            name:"pricing",
            component:  () => import("@/components/pages/Pricing.vue"),
        },
       
    ]
})

  
  export default router;